import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Menu from "../components/menu";
import Footer from "../components/footer";
import User from "./user";
//import PortfConfig from "../containers/portfolio/ptfconfigure";
import PortfManage from "../containers/portfolio/ptfmanage";
import RebalanceSelect from "../containers/portfolio/rebalanceSelect";
import SignalTrades from "../containers/signaltrades";
import sysconfig from "../config";
import { roleConstrainter, portfolioConstrainter } from "../utils/clientConstraint";

function PortfolioMenu(props) {
  const [rebalances, setRebalances] = useState(false);
  const [manage, setManage] = useState(false);
  const [trades, setTrades] = useState(false);
  const [intro, setIntro] = useState(false);
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const partner = useSelector((state) => state.partner);

  const sideMenu = () => {
    let cpRebalances = <div />;
    let cpManage = <div />;
    let cpTrades = <div />;

    cpRebalances = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setRebalances(true);
              setManage(false);
              setTrades(false);
            }}
          >
            History
          </span>
        </div>
      </div>
    );

    cpManage = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setRebalances(false);
              setManage(!portfolioConstrainter(user) ? true : false);
              setTrades(false);
            }}
          >
            Manage
          </span>
        </div>
      </div>
    );

    cpTrades = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setRebalances(false);
              setManage(false);
              setTrades(true);
            }}
          >
            Trades
          </span>
        </div>
      </div>
    );

    let result = (
      <div className="col-md-1">
        <p className="mt-5">
          <span
            className="btn-link cursor-pointer text-dark"
            onClick={() => {
              setIntro(true);
              setRebalances(false);
              setManage(false);
              setTrades(false);
            }}
          >
            PORTFOLIO
          </span>
        </p>
        {cpManage}
        {cpRebalances}
        {cpTrades}
      </div>
    );

    return result;
  };

  const introduction = () => {
    if (!intro) return <div />;
    return (
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-11">
          <br />
          <p className="text-secondary font-italic">
            At this space you are able to create and manage your own portfolio. <br />
            At anytime you may assign a USD@ value for deposit to be managed by the portfolio.{" "}
            <br />
            Input desired portfolio assets (coins) and respective percentages. <br />
            Then select a rebalancing type:
            <br />
            * Manual - Rebalance commanded by user. <br />
            * Periodic - Rebalance every given period (ex. 4 weeks).
            <br />
            * Threshold - Rebalance when some asset value overtakes up or down limits. <br />
            Example: Portfolio with: [BTC 50%, ETH 30%, DOT 20%] and allowed variation of 5%. <br />
            If at a given moment BTC volume represents more than 55% or less than 45% a rebalancing
            will be triggered with buy/sell orders to readjust desired volumes. <br />
            History shows you the results of each rebalancing on a time line, <br />
            and Trades presents a report with actual buy/sell portfolio operations.
          </p>
        </div>
      </div>
    );
  };

  const render = () => {
    let role = user ? user.role : false;
    let showManage = manage ? <PortfManage /> : <div />;
    let showRebalances = rebalances ? <RebalanceSelect user={user} /> : <div />;
    let showTrades = trades ? <SignalTrades product="portfolio, terminal" /> : <div />;
    let showIntroduction = introduction();
    let sidemenu = sideMenu();
    let toRender = <div />;
    if (user && user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            {showIntroduction} {showManage} {showRebalances} {showTrades}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu logged={role} source={partner} user={user} />
        <div className="container-fluid">
          <User
            email={user.email}
            source=""
            showSelection={false}
            showOnOff={false}
            noShow={true}
          />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  };
  if (!token) {
    window.location.replace(sysconfig.HOME_URL);
    return null;
  }
  return render();
}

//promote Login from a component to a container with added props activeToken
export default PortfolioMenu;
