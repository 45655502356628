import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import validator from "validator";

import RebalanceTrigger from "../../components/portfolio/rebalanceTrigger";
import sysconfig from "../../config";

function PortfManage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState("");
  const [deviation, setDeviation] = useState("");
  const [period, setPeriod] = useState("");
  const [frame, setFrame] = useState("");
  const [addPtf, setAddPtf] = useState(false);
  const [indPtf, setIndPtf] = useState(-1);
  const [portfolios, setPortfolios] = useState([]);
  const [portfolio, setPortfolio] = useState({});
  const [assets, setAssets] = useState([]);
  //const [state, setState] = useState({});
  const [status, setStatus] = useState(false);
  const [contribution, setContribution] = useState(0);
  const [quoteAsset, setQuoteAsset] = useState("USDT");
  const [delegate, setDelegate] = useState(user.configPortf.delegate);
  const [btnSubmit, setBtnSubmit] = useState("Save");
  const [btnRebalance, setBtnRebalance] = useState("Rebalance");
  const [isBtnSaveDisabled, setIsBtnSaveDisabled] = useState(false);
  const [isBtnRebalanceDisabled, setIsBtnRebalanceDisabled] = useState(true);
  const [msg, setMsg] = useState("");
  const [newAsset, setNewAsset] = useState("");
  const [newPerc, setNewPerc] = useState(0);
  const [addAsset, setAddAsset] = useState(false);

  const handleSaveClick = (event) => {
    try {
      let assetsMap = assets.map(({ asset, percentage }) => ({
        asset,
        percentage,
      }));
      let msg = validate(assetsMap);
      if (msg !== "OK") return alert("Error: " + msg);
      setAddPtf(false);
      setMsg("");
      setIsBtnSaveDisabled(true);
      setIsBtnRebalanceDisabled(true);
      setBtnSubmit("Wait...");
      let data = {
        userkey: user.userkey,
        name,
        loggedUser: user.username,
        email: user.email,
        contribution,
        quoteAsset,
        trigger,
        deviation,
        period,
        frame,
        status: status ? "ON" : "OFF",
        assets: assetsMap,
      };
      let url = user.role === "trader" ? "/portfolio/leader/save" : "/portfolio/enduser/save";

      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url,
        headers: { "x-auth": token },
        data,
      })
        .then(function (response) {
          setBtnSubmit("Save");
          setIsBtnSaveDisabled(false);
          setIsBtnRebalanceDisabled(false);
          setMsg("Portfolio saved!");
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          setBtnSubmit("Save");
          setIsBtnSaveDisabled(false);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Exception: " + e.message);
    }
  };

  const handleRebalanceClick = (event) => {
    try {
      if (trigger !== "MANUAL") return alert("Error: Only for MANUAL rebalance");
      setIsBtnRebalanceDisabled(true);
      setBtnRebalance("Wait...");
      setMsg("");
      let data = {
        userkey: user.userkey,
        name,
        loggedUser: user.username,
        email: user.email,
        contribution,
        trigger,
        status: "ON",
      };
      let url =
        user.role === "trader" ? "/portfolio/leader/rebalance" : "/portfolio/enduser/rebalance";

      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url,
        headers: { "x-auth": token },
        data,
      })
        .then(function (response) {
          setBtnRebalance("Rebalance");
          setIsBtnRebalanceDisabled(false);
          setMsg("Portfolio rebalanced!");
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          setBtnRebalance("Rebalance");
          setIsBtnRebalanceDisabled(true);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Exception: " + e.message);
    }
  };

  //equivalent to didMount
  useEffect(() => {
    getPortfolios();
  }, [portfolios]);

  const getPortfolios = () => {
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/portfolio/list",
      headers: { "x-auth": token },
      params: { email: user.email, loggedUser: user.username },
    })
      .then(function (response) {
        if (response.data) {
          setPortfolios(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err getPortfolios:", error);
      });
  };

  const onInputChange = (event) => {
    switch (event.target.id) {
      case "contribution":
        setContribution(event.target.value);
        break;
      case "name":
        setName(event.target.value);
        break;
      default:
        setDelegate(event.target.value);
        break;
    }
  };

  const handlePtfSelect = (event) => {
    setName(event.target.value);
    let ptfSelected = portfolios.find((elem) => elem.name === event.target.value);
    let indPtf = portfolios.findIndex((elem) => elem.name === event.target.value);
    setIndPtf(indPtf);
    setPortfolio(ptfSelected);
    setTrigger(ptfSelected.trigger);
    setDeviation(ptfSelected.deviation);
    setPeriod(ptfSelected.period);
    setFrame(ptfSelected.frame);
    setAssets(ptfSelected.assets);
    setStatus(ptfSelected.status === "ON");
    setAddPtf(false);
  };

  const handleQuoteChange = (event) => {
    setQuoteAsset(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(!status);
  };

  const handleAddPtfClick = (event) => {
    setName("My Portfolio");
    setPortfolio({});
    setAssets([]);
    setAddPtf(true);
    setTrigger("MANUAL");
  };

  const handleAddAssetClick = (event) => {
    if (name === "") {
      alert("Add or select a portfolio first");
      return false;
    }
    setAddAsset(true);
  };

  const handleConfirmAssetClick = (event) => {
    let ind = assets.findIndex((doc) => doc.asset.toUpperCase() === newAsset.toUpperCase());
    if (ind === -1) {
      assets.push({ asset: newAsset, percentage: newPerc });
    } else assets[ind].percentage = newPerc;
    setAssets(assets);
    portfolio.assets = assets;
    setPortfolio(portfolio);
    portfolios[indPtf] = portfolio;
    setPortfolios(portfolios);
    setAddAsset(false);
    setNewAsset("");
    setNewPerc(0);
  };

  const onAssetChange = (event) => {
    setNewAsset(event.target.value.toUpperCase());
  };

  const onPercChange = (event) => {
    setNewPerc(Number(event.target.value));
  };

  const handleAssetDelClick = (event) => {
    let ind = event.target.id;
    if (assets[ind].percentage === 0) {
      assets.splice(ind, 1);
      setAssets(assets);
      portfolio.assets = assets;
      setPortfolio(portfolio);
      portfolios[indPtf] = portfolio;
      setPortfolios(portfolios);
    } else alert("Save with zero percentage first, before removing");
  };

  const handleAssetCancelClick = (event) => {
    setAddAsset(false);
    setNewAsset("");
    setNewPerc(0);
  };

  const cpPtflist = () => {
    let items = [];
    let result = <div />;
    let _addPtf = cpAddPtf();
    for (let ptf of portfolios) {
      items.push(
        <div className="form-group form-group-sm form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id={ptf.name}
            value={ptf.name}
            checked={name === ptf.name}
            onChange={handlePtfSelect}
          />
          <label className="form-check-label">
            <small>{ptf.name}</small>
          </label>
        </div>
      );
    }
    if (addPtf) {
      result = (
        <input
          className="form-control"
          id="name"
          placeholder="Portfolio name"
          value={name}
          onChange={onInputChange}
        />
      );
    } else
      result = (
        <div className="row">
          <div className="col-md-10">{items}</div>
          <div className="col-md-2">{_addPtf}</div>
        </div>
      );
    return result;
  };

  const cpAddPtf = () => {
    let result = (
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm"
        onClick={handleAddPtfClick}
      >
        Add portfolio
      </button>
    );
    return result;
  };

  const cpStatus = () => {
    if (name === "") return <div />;
    let result = (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="status"
          checked={status}
          onChange={handleStatusChange}
        />
        <label
          className="custom-control-label"
          for="status"
          data-tip="Turn portfolio execution ON / OFF "
        >
          <small>Status</small>
        </label>
      </div>
    );
    return result;
  };

  const btnAddAsset = () => {
    if (name === "") return <div />;
    let result = (
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm"
        onClick={handleAddAssetClick}
      >
        New asset
      </button>
    );
    return result;
  };

  const cpDelegateLine = () => {
    if (name === "") return <div />;
    if (user.role === "trader") return <div />;
    let result = (
      <div className="form-group">
        <small>Leader to Follow</small>
        <input
          className="form-control"
          id="delegate"
          placeholder="Username to follow"
          value={delegate}
          disabled
          onChange={onInputChange}
        />
      </div>
    );
    return result;
  };

  const cpContribution = () => {
    if (name === "") return <div />;
    if (user.role === "trader") return <div />;
    let result = (
      <div className="form-group">
        <div className="row">
          <div className="col-md-4">
            <small data-tip="Set new USD amount to be bought into the portfolio">Deposit</small>
            <input
              className="form-control form-control-sm"
              id="contribution"
              placeholder="portf. deposit"
              value={contribution}
              disabled={user.role === "trader"}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-5">
            <small data-tip="Buy portfolio assets from this">Quote Asset</small>
            <select
              className="form-control form-control-sm"
              id="quoteAsset"
              value={quoteAsset}
              onChange={handleQuoteChange}
            >
              <option id="usdt" value="USDT" selected={quoteAsset === "USDT"}>
                USDT
              </option>
            </select>
          </div>
        </div>
      </div>
    );
    return result;
  };

  const cpTrigger = () => {
    if (name === "") return <div />;
    let result = (
      <RebalanceTrigger
        trigger={trigger}
        deviation={portfolio.deviation}
        frame={portfolio.frame}
        period={portfolio.period}
        sendData={getData}
      />
    );
    return result;
  };

  const getData = (val) => {
    //receive data from child component
    if (val.trigger) setTrigger(val.trigger);
    if (val.deviation) setDeviation(val.deviation);
    if (val.frame) setFrame(val.frame);
    if (val.period) setPeriod(val.period);
  };

  const cpAssets = () => {
    //To dinamically generate assets components I use assets array and state object, both syncronized
    //Assets is used to iterate and state keeps track of input names and values
    //assets[0]='BNB', assets[1]='BTC' ==> state.01asset = 'BNB', state.02asset=BTC
    if (name === "") return <div />;
    let result = "";
    let i = 0;
    let cpAsset = [];
    for (let asset of assets) {
      //onChange={event => onAssetChange(event, i)}
      if (!asset.action || asset.action !== "REMOVE") {
        cpAsset.push(
          <div className="row">
            <div className="col-md-3">
              <small>{asset.asset}</small>
            </div>
            <div className="col-md-3">
              <small>{asset.percentage}</small>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-outline-light btn-sm cursor-pointer"
                key={i}
                id={i}
                onClick={handleAssetDelClick}
              >
                <img id={i} src="/images/trash.png" height="10" width="10" />
              </button>
            </div>
          </div>
        );
      }
      i++;
    }
    //<div className="form-group form-group-sm form-check form-check-inline">
    result = (
      <div className="form-group form-group-sm">
        <div className="row">
          <div className="col-md-3">
            <small>Asset</small>
          </div>
          <div className="col-md-3">
            <small>Percentage</small>
          </div>
          <div className="col-md-3" />
        </div>
        {cpAsset}
      </div>
    );
    return result;
  };

  const cpNewAsset = () => {
    if (name === "" || !addAsset) return <div />;
    let result = "";
    let i = 0;
    let cpAsset = [];
    cpAsset.push(
      <div className="row">
        <div className="col-md-3">
          <input
            className="form-control form-control-sm"
            placeholder="Asset"
            value={newAsset}
            onChange={onAssetChange}
          />
        </div>
        <div className="col-md-3">
          <input
            className="form-control form-control-sm"
            placeholder="Percentage"
            value={newPerc}
            onChange={onPercChange}
          />
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-outline-light btn-sm cursor-pointer"
            onClick={handleConfirmAssetClick}
          >
            <img src="/images/positive.gif" height="20" width="20" />
          </button>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-outline-light btn-sm cursor-pointer"
            key="cancel"
            id="cancel"
            value={newAsset}
            onClick={handleAssetCancelClick}
          >
            <img id="cancel" src="/images/negative.gif" height="20" width="20" />
          </button>
        </div>
      </div>
    );

    result = <div className="form-group form-group-sm">{cpAsset}</div>;
    return result;
  };

  const cpSave = () => {
    if (name === "") return <div />;
    return (
      <button
        type="button"
        className="btn btn-primary"
        disabled={isBtnSaveDisabled}
        onClick={(event) => handleSaveClick()}
      >
        {btnSubmit}
      </button>
    );
  };

  const cpRebalance = () => {
    if (trigger !== "MANUAL") return <div />;
    return (
      <button
        type="button"
        className="btn btn-primary"
        disabled={isBtnRebalanceDisabled}
        onClick={(event) => handleRebalanceClick()}
      >
        {btnRebalance}
      </button>
    );
  };

  const btns = () => {
    let _save = cpSave();
    let _rebalance = cpRebalance();
    return (
      <div className="form-group">
        <br />
        <div className="row" align="left">
          <div className="col-md-6">{_save}</div>
          <div className="col-md-6">{_rebalance}</div>
        </div>
      </div>
    );
  };

  const validate = (assetsMap) => {
    let result = "OK";
    let percTotal = 0;
    for (let asset of assetsMap) {
      if (!validator.isNumeric(asset.percentage.toString())) {
        return "Invalid percentage value";
        break;
      }
      percTotal += Number(asset.percentage);
    }
    if (percTotal !== 100) return "Percentages must sum 100";
    if (!contribution) return result;
    if (!validator.isNumeric(contribution.toString())) {
      return "Invalid Number field";
    }
    if (Number(contribution) !== 0 && Number(contribution) < 50) {
      return "USD 50 minimum contribution";
    }
    if (period && !validator.isNumeric(period.toString())) {
      return "Invalid Period";
    }
    if (deviation && !validator.isNumeric(deviation.toString())) {
      return "Invalid threshold variation";
    }
    if (quoteAsset === "USD" && user.exchange === "binance")
      return "Invalid Quote Asset for Binance";

    return result;
  };

  const panel = () => {
    let _ptflist = cpPtflist();
    let _status = cpStatus();
    let _delegateLine = cpDelegateLine();
    let _contribution = cpContribution();
    let _trigger = cpTrigger();
    let _assets = cpAssets();
    let _cpNewAsset = cpNewAsset();
    let _btnAddAsset = btnAddAsset();
    let _btns = btns();

    return (
      <div>
        <div className="w-50">
          <center>
            <h5>My Portfolio</h5>
          </center>
          {_ptflist}
          {_status}
          <br />
          {_delegateLine}
          {_contribution}
          {_trigger}
          {_assets}
          {_cpNewAsset}
          {_btnAddAsset}
          {_btns}
        </div>
        <div className="text-info mt-2">{msg}</div>
      </div>
    );
  };

  let _panel = panel();
  return (
    <div className="row">
      <div className="col-md-2" />
      <div className="col-md-10">
        {_panel}
        <ReactTooltip place="top" type="info" multiline="true" effect="float" />
      </div>
    </div>
  );
}

export default PortfManage;
