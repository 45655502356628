import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";

import sysconfig from "../config";
import { selectToken, selectPartner } from "../actions/root";
import AccountMenu from "./accountMenu";
import Login from "./login";

class Home extends Component {
  constructor(props) {
    super(props);
    let source;
    let email;
    let token;
    if (this.props && this.props.partner) {
      source = this.props.partner;
    } else {
      //this is the case of integrated Groups
      source =
        this.props.match && this.props.match.params.source
          ? this.props.match.params.source
          : "none";
      email =
        this.props.match && this.props.match.params.email
          ? this.props.match.params.email.toLowerCase()
          : "";
      token =
        this.props.match && this.props.match.params.token ? this.props.match.params.token : "";
      if (token) {
        this.sumTokenLogin(token);
      }
    }
    if (source && !this.props.partner) {
      this.props.selectPartner(source);
    }
    if (token && !this.props.token) {
      this.props.selectToken(token);
    }
    this.state = { email };
  }

  sumTokenLogin(token) {
    var _this = this;
    axios({
      method: "POST",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/tokenLogins",
      headers: { "x-auth": token },
      data: {
        token: token,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (Number(response.data) > 1) _this.props.selectToken(null);
        }
      })
      .catch(function (error) {
        console.log(error);
        return 0;
      });
  }

  render() {
    var display;
    if (this.props.token && this.props.token.length > 50) {
      display = <AccountMenu email={this.state.email} />;
    } else {
      display = <Login />;
    }
    return display;
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    token: state.activeToken,
    partner: state.activePartner,
  };
}

//anything returned from this function will become props on this container
function mapDispatchToProps(dispatch) {
  //whenever selectToken is called, the result will be passed to all reducers
  return bindActionCreators({ selectToken, selectPartner }, dispatch);
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps, mapDispatchToProps)(Home);
