import React, { useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";

import sysconfig from "../config";

import Menu from "./menu";
import Footer from "./footer";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const onInputChange = (event) => {
    let value = event.target.value.trim();
    let lastChar = value.slice(-1);
    if (/^[a-zA-Z0-9@._-\s]*$/.test(lastChar)) {
      setEmail(event.target.value.trim());
    }
  };

  const handleRegisterClick = (event) => {
    try {
      if (!validator.isEmail(email)) {
        throw new Error("Invalid email");
      }
      setEmail("");
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/forgotpw",
        data: {
          api: sysconfig.CLIENT_KEY,
          email: email,
        },
      })
        .then(function (response) {
          setMsg("Please verify your email and change the password after first login");
        })
        .catch(function (error) {
          console.log(error);
          alert("Error: " + error.message);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Error: " + e.message);
    }
  };

  return (
    <div>
      <Menu logged={false} />
      <p />
      <form>
        <div className="container w-75 form-group">
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email to receive the new password"
              value={email}
              onChange={onInputChange}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary mr-4"
            onClick={(event) => handleRegisterClick()}
          >
            Reset
          </button>
          <div className="text-danger mt-2">{msg}</div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
