import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
// import {
//   faPlusSquare,
//   faFileAlt,
//   faChartLine,
//   faChartArea,
//   faTrashAlt,
//   faSyncAlt,
//   faUsers
// } from "@fortawesome/free-solid-svg-icons";

import Signup from "./components/signup";
import Forgot from "./components/forgotPassword";
import Home from "./containers/home";
import Signout from "./containers/signout";
import PerformDetail from "./components/performDetail";
import ReportsMenu from "./containers/reportsMenu";
import PortfolioMenu from "./containers/portfolioMenu";
import SignalsMenu from "./containers/signalsMenu";
import AutomationMenu from "./containers/automationMenu";
import FuturesMenu from "./containers/futuresMenu";

//****
//import PerformanceReport from "./containers/performreport";
//import Arbitrage from "./containers/arbitrage";
//import Dashboard from "./containers/dashboard";
//import LogMonitor from "./containers/logmonitor";
//import Payments from "./containers/payments";
//library.add(fab, faPlusSquare, faFileAlt, faChartLine, faChartArea, faTrashAlt, faSyncAlt, faUsers);

class App extends Component {
  render() {
    //    <Route exact path="/performance" component={PerformanceReport} />
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/signout" element={<Signout />} />
            <Route exact path="/forgotPassword" element={<Forgot />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/performance" element={<PerformDetail />} />
            <Route exact path="/reports" element={<ReportsMenu />} />
            <Route exact path="/signals" element={<SignalsMenu />} />
            <Route exact path="/portfolio" element={<PortfolioMenu />} />
            <Route exact path="/futuresMenu" element={<FuturesMenu />} />
            <Route exact path="/automation" element={<AutomationMenu />} />
            <Route exact path="/:source/:email/:token" element={<Home />} />
            <Route path="/:source" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

// <Route exact path="/dashboard" component={Dashboard} />
// <Route exact path="/log" component={LogMonitor} />
